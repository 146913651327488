import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout-code';

export default () => (
  <Layout>
    <h1>Artful Coding</h1>
    <p>
      Originally a means to customize the presentation of my creative work, web
      coding has become another creative outlet
    </p>
    <Link to="/">&larr; Back to Homepage</Link>
  </Layout>
);
